import React, { Component } from "react";
import Payment from "../../components/payment/Payment";
import axios from "@/axios";
import { applyVueInReact } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";
class PremierInsurance extends Component {
  LoadingSpinner = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      message: "",
      reports: "",
      acceptanceNo: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }
  checkPremierPolicy = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (this.state.acceptanceNo.length === 0) {
      this.setState({
        message: "Please enter your Acceptance number.",
        loading: false,
      });

      return;
    }

    const data = await axios.post("api/v2/services/insurance/checkpolicy", {
      InsuranceName: "PremierInsurance",
      AcceptanceNo: this.state.acceptanceNo,
    });
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response && data.data.response.Code === "000") {
      this.setState({ reports: data.data.response });
    } else {
      this.setState({ message: data.data.response.Message });
    }
  };

  handleAccChange = (event) => {
    this.setState({ acceptanceNo: event.target.value.trim() });
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Premier Insurance.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Acceptance No:</div>
                      <div>{this.state.acceptanceNo}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Proforma No</div>
                      <div>{this.state.reports.ProformaNo}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Customer Number</div>
                      <div>{this.state.reports.Name}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Class Name</div>
                      <div>{this.state.reports.ClassName}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Sum Insured Amount</div>
                      <div>{this.state.reports.SuminsuredAmount} NPR</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Total Premium</div>
                      <div>{this.state.reports.TotalPremium} NPR</div>
                    </div>
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/insurance/payment"
                        paymentPayload={{
                          Amount: this.state.reports.TotalPremium,
                          InsuranceName: "PremierInsurance",
                          PolicyNo: this.state.acceptanceNo,
                          PolicyName: this.state.reports.Name,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.Name,
                          "Total Amount to Pay":
                            "Rs. " + this.state.reports.TotalPremium,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>Acceptance No:</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Acceptance No. and press enter."
                        value={this.state.acceptanceNo}
                        onChange={this.handleAccChange}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkPremierPolicy}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PremierInsurance;
